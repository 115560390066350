<script>
  import GanttHeader from "$lib/GanttHeader.svelte";
  import GanttRow from "$lib/GanttRow.svelte";
  import { primaryScale, windowEndKm, windowEndM, windowStartKm, windowStartM } from '../stores';
// import trackItemsResponse from '../TrackView.svelte';
// import { primaryColumns, vocabMap, zoomLevel } from "../stores.js";
  import { getContext } from "svelte";
  import { apiGET, getVocabMap } from "../api";

  export let items = [];
  let unsharpeningFactor = 100;

  const rowlabeltype = getContext("rowlabeltype");
  const rooturl = getContext("rooturl");
  // const objecturl = getContext("objecturl");
  let colorMapping = getContext("colorMapping");
  let categoryField = getContext("categoryField");
  console.log(`categoryField: ${categoryField}`)
  if (categoryField === 'Facility'){
    categoryField = `${categoryField}_raw`;
  }


  let query = {
    sort_on: 'getObjPositionInParent',
    sort_order: 'ascending',
  }
  query["criteria"] = [{
    i: 'portal_type',
    o: 'plone.app.querystring.operation.selection.any',
    v: rowlabeltype,
  }]

  const query_attributes = [
    "UID",
    "Title",
  ]

  // used to find row labels from CT's:
  let searchurl = new URL(rooturl);
  if(searchurl.pathname.endsWith("/view")) {
    searchurl.pathname = searchurl.pathname.replace("/view", "");
  }
  searchurl.pathname += "/@@getVocabulary"
  let searchParams = new URLSearchParams(searchurl.search);
  searchParams.set("name", "plone.app.vocabularies.Catalog")
  searchParams.set("query", JSON.stringify(query))
  searchParams.set("attributes", JSON.stringify(query_attributes))
  // searchParams.append("metadata_fields", "UID")
  searchurl.search = searchParams.toString();

  let rowHeight = 30;
  let rowCategories = new Map();
  let primaryHeaders;

  function resolveOffset(value){
    let resolved = (value - $windowStartM) / unsharpeningFactor;
    console.log(`resolved ${resolved}`)
    // console.log(`resolveOffset: (${value} / ${unsharpeningFactor}) % ((${$primaryScale} * 1000) / ${unsharpeningFactor}) = ${resolved}`)
    return resolved.toFixed()
  }

  function calcItemSpan(start, end) {
    if(!start){
      return
    }
    if(!end){
      return
    }
    const startM = start;
    const endM = end;
    let span;
    let itemSpan;
    let itemStartInWindow;
    let itemEndInWindow;
    let overflowLeft = false;
    let overflowRight = false;
    console.log(`winStart/End: ${$windowStartM}/${$windowEndM}`)
    if(startM > $windowEndM || endM < $windowStartM) return;
    if(startM < $windowStartM) {
      itemStartInWindow = $windowStartM;
      overflowLeft = true;
    }else{
      itemStartInWindow = startM;
    }
    if(endM > $windowEndM){
      itemEndInWindow = $windowEndM;
      overflowRight = true;
    }else{
      itemEndInWindow = endM;
    }
    span = (itemEndInWindow - itemStartInWindow) / unsharpeningFactor;

    itemSpan = {
      startM: start,
      endM: end,
      start: resolveOffset(itemStartInWindow),
      span: span,
      overflowLeft: overflowLeft,
      overflowRight: overflowRight,
    };
    console.log(itemSpan)
    return itemSpan;
  }

  class CatRow {
    constructor(
      rowColorBg,
      rowColorFont,
      rowItemColorBg,
      rowItemColorFont,
      items
    ) {
      this.rowColorBg = rowColorBg;
      this.rowColorFont = rowColorFont;
      this.rowItemColorBg = rowItemColorBg;
      this.rowItemColorFont = rowItemColorFont;
      this.items = items;
    }
  }

  function configureCatRow(catRow, categoryToken){
    // move this data to probs of the view, to avoid the call
    if (!colorMapping) {
      colorMapping = [
        {
          row_label: "",
          row_color_fg: "transparent",
          row_color_font: "black",
          row_item_color_fg: "lightblue",
          row_item_color_font: "black",
        },
      ];
    }
    for (const colorMap of colorMapping) {
      if (colorMap.row_label === categoryToken) {
        catRow.rowColorBg = colorMap.row_color_bg;
        catRow.rowColorFont = colorMap.row_color_font;
        catRow.rowItemColorBg = colorMap.row_item_color_bg;
        catRow.rowItemColorFont = colorMap.row_item_color_font;
      }
    }
    return catRow;
  }

  async function buildRows(items) {
    let rowCategories = new Map();
    // const vocab = await getVocabMap(searchurl.href);
    for (const item of items) {
      let rowItem;
      let category = "other";
      let catRow;
      const categoryTokenField = item[categoryField];

      let categoryToken = undefined;

      if(typeof categoryTokenField === "string"){
        category = categoryTokenField;
        categoryToken = category;
      }else{
        if (categoryTokenField){
          categoryToken = categoryTokenField.UID;
          category = categoryTokenField.title;
        }
      }

      if (rowCategories.has(category)) {
        catRow = rowCategories.get(category);
      } else {
        catRow = new CatRow("transparent", "black", "lightblue", "black", []);
        catRow = configureCatRow(catRow, categoryToken)
        rowCategories.set(category, catRow);
      }

      let itemSpan = calcItemSpan(item.start_m, item.end_m);
      if (!itemSpan) continue;
      rowItem = {
        name: item.title,
        description: item.description,
        startM: itemSpan.startM,
        endKm: itemSpan.endM / 1000,
        startKm: itemSpan.startM /1000,
        endM: itemSpan.endM,
        start: itemSpan.start,
        span: itemSpan.span,
        overflowLeft: itemSpan.overflowLeft,
        overflowRight: itemSpan.overflowRight,
        url: item["@id"],
        uid: item.UID,
      };
      catRow.items = [...catRow.items, rowItem];
      rowCategories.set(category, catRow);
    }
    console.log(rowCategories)
    return rowCategories;
  }

  $: {
    console.log("build rows, calculate span and offset...");
    $windowStartKm;
    $windowEndKm;
    (async () => (rowCategories = await buildRows(items)))();
  }

  // $: {
  //   $primaryScale = $primaryScale;
  //   console.log($primaryScale)
  // }

  $: {
    primaryHeaders = [...Array(($primaryScale)).keys()].map(x => {
      let i = x + $windowStartKm;
      return { id: i, name: `${i}` };
    });
    console.log(primaryHeaders)
  }
</script>
<div class="GanttTable">
  <div id="popover-content" />
  <GanttHeader {primaryHeaders} />
  {#each [...rowCategories] as [catLabel, catRow]}
    <GanttRow
      rowId={catLabel}
      {rowHeight}
      gridcolumns={($primaryScale * 1000 / unsharpeningFactor)}
      --row-color-font={catRow.rowColorFont}
      --row-color-bg={catRow.rowColorBg}
      --row-item-color-font={catRow.rowItemColorFont}
      --row-item-color-bg={catRow.rowItemColorBg}
      items={catRow.items}
    />
  {/each}
</div>

<style>
  .GanttTable {
    max-width: 100%;
  }
</style>
